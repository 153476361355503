<template>
  <page-layout>
    <div slot="action">
      <a-input-search
        placeholder="搜索宝贝"
        enter-button
        @search="onSearch"
        v-model="query.storeName"
      />
    </div>
    <div id="collection">
      <a-spin :spinning="spinning">
        <a-row :gutter="16">
          <a-col
            :xxl="4"
            :xl="6"
            :sm="12"
            v-for="(item, i) in list"
            :key="i"
            class="box"
          >
            <a-card
              hoverable
              @click="
                !item.is_show
                  ? ''
                  : $router.push({
                      path: 'goodsDetail',
                      query: { id: item.product_id },
                    })
              "
              style="width: 100%; height: 379px; box-sizing: border-box"
            >
              <img alt="example" :src="item.image" class="img" />
              <div class="store_name">{{ item.store_name }}</div>
              <div class="green price" style="margin-top: 15px">
                ￥{{ item.price | price }}
              </div>
              <div class="gray mart5 ot_price">
                <span style="text-decoration: line-through">
                  ￥{{ item.ot_price | price }}
                </span>
                <!-- /已售{{ item.sales }} -->
              </div>
              <img
                src="@/assets/img/close_icon.png"
                class="close"
                @click.stop="closeCollection(i)"
              />
              <div class="mask_box" v-show="closeIndex == i">
                <div class="text">确定取消收藏？</div>
                <div class="button_box">
                  <div class="button" @click.stop="canale(item.product_id)">
                    确定
                  </div>
                  <div class="cancle" @click.stop="canaleClose(i)">取消</div>
                </div>
              </div>
              <div class="mask_box z9" v-show="!item.is_show">
                <p class="no_show">该商品已下架</p>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </a-spin>
      <div class="nodata" v-if="list.length == 0">
        <img src="@/assets/img/haveNoData.png" alt="" />
        <div>暂无商品收藏</div>
      </div>
      <div style="margin-top: 20px; text-align: right">
        <a-pagination
          v-model="current"
          :page-size-options="pageSizeOptions"
          :page-size="pageSize"
          :total="total"
          show-size-changer
          v-if="total != 0"
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import { collectList, delCollect } from "@/api/collection";
import { price } from "@/utils/filters";
export default {
  name: "collection",
  components: { PageLayout },
  filters: { price },
  data() {
    return {
      pageSizeOptions: ["12", "24", "36", "48", "60"],
      list: [],
      current: 1,
      pageSize: 12,
      total: 0,
      query: {
        page: 1,
        c: 12,
        storeName: "",
      },
      showmask: false,
      closeIndex: -1,
      spinning: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    canaleClose() {
      this.closeIndex = -1;
    },
    closeCollection(index) {
      this.closeIndex = index;
    },
    onSearch() {
      this.getList();
    },
    getList() {
      this.spinning = true;
      collectList(this.query)
        .then((res) => {
          if (res.data.data.count == 0) {
            this.total = 0;
            this.list = [];
          } else {
            this.list = res.data.data.arr;
            this.total = res.data.data.count;
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    canale(id) {
      delCollect({
        id: id,
      }).then((res) => {
        this.$message.success(res.data.msg);
        this.getList();
      });
    },
    onChange(page, pageSize) {
      this.pageSize = pageSize;
      this.current = page;
      this.query.page = page;
      this.query.page = pageSize;
      this.getList();
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.query.page = current;
      this.query.c = pageSize;
      this.getList(current, pageSize);
    },
  },
};
</script>

<style>
.ant-card-meta-title {
  font-size: 14px;
}
.ant-card-meta-description {
  text-align: center;
}
</style>

<style lang="less" scoped>
@import "collection";
</style>
